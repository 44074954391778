import { createWebHistory, createRouter } from 'vue-router';

const routes = [
    {
        path: '/login',
        name:'Login',
        component: ()=> import('@/views/Login'),

    },
    {
        path: '/',
        component: ()=> import('@/views/dashboard/Dashboard'),
        children: [
            {
                path:'/',
                name: 'Home',
                component: () => import('@/views/dashboard/pages/Home')
            },
            {
                path:'/about',
                name:'About',
                component: () => import('@/views/dashboard/pages/About')
            },
            {
                path: '/user/:id?',
                name:'User',
                component:  () => import('@/views/dashboard/pages/User')
            },
            {
                path: '/user/list',
                name:'UserList',
                component:  () => import('@/views/dashboard/pages/UserList')
            },
            {
                path: '/invoice/:id?',
                name:'Invoice',
                component:  () => import('@/views/dashboard/pages/Invoice')
            },
            {
                path: '/invoice/list',
                name:'InvoiceList',
                component:  () => import('@/views/dashboard/pages/InvoiceList')
            },              
        ],
    },
    {
        path: '/deal/',
        component: ()=> import('@/views/dashboard/Dashboard'),
        children: [
            {
                path: '/list/',
                name:'ListDeal',
                component:  () => import('@/views/dashboard/pages/deal/ListDeal')
            },
            {
                path: '/create/:id?',
                name:'NewDeal',
                component:  () => import('@/views/dashboard/pages/deal/NewDeal')
            },
            {
                path: '/config/:id?',
                name:'ConfigDeal',
                component:  () => import('@/views/dashboard/pages/deal/ConfigDeal')
            },
        ],
    }, 
    {
        path: '/partner/',
        component: ()=> import('@/views/dashboard/Dashboard'),
        children: [
            {
                path: '/list-partner/',
                name:'ListPartner',
                component:  () => import('@/views/dashboard/pages/partner/ListPartner')
            },
            {
                path: '/partner/:id?',
                name:'Partner',
                component:  () => import('@/views/dashboard/pages/partner/Partner')
            },
        ],
    },      
    {
        path: "/:catchAll(.*)",
        component: () =>import('@/views/NotFound'),
      },
      
];




const router =  createRouter({
    history: createWebHistory(),
    routes
});

export default router;


router.beforeEach((to, from, next) =>{
    const publicPages = ['/login'];

    const authRequired  = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');

    if(authRequired && !loggedIn){
        return next({
            path: '/login',
            query: { returnUrl: to.path}
        })
    }

    next();
})