import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from "axios";
import {Decimal} from 'decimal.js';
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API
})
  
const app = createApp(App).use(router)


app.config.globalProperties.$http = { ...axiosInstance }
app.config.globalProperties.Decimal = Decimal;
app.config.globalProperties.$notyf = new Notyf();

app.mount('#app');